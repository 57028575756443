<template>
  <div
    class="h-100 w-100 px-2 my-2"
    data-bs-spy="scroll"
    data-bs-target="#scroll-spy-link"
    data-bs-smooth-scroll="true"
    data-bs-root-margin="0px 0px 40%"
  >
    <div v-if="store.state?.workplacesLoading" class="d-flex flex-column justify-content-center w-100 p-5">
      <h1 class="d-flex justify-content-center">Идёт загрузка данных</h1>
      <ProgressBar :value="(100 * store.state.workplacesLoading.progress) / store.state.workplacesLoading.count">
        {{ store.state.workplacesLoading.progress }} / {{ store.state.workplacesLoading.count }}</ProgressBar
      >
    </div>

    <h1
      v-if="!store.state.workplacesLoading && (props.list?.length == 0 || props.fields?.length == 0)"
      class="d-flex justify-content-center p-5"
    >
      Нет данных для выбранного региона
    </h1>

    <DataTable
      v-if="!store.state.workplacesLoading && props.list.length && props.fields?.length"
      v-model:selection="selectedItem"
      :value="props.list"
      :rows="100"
      :rowsPerPageOptions="[100, 500, 1000]"
      selectionMode="single"
      dataKey="id"
      scrollHeight="flex"
      scrollable
      removableSort
      paginator
      stripedRows
    >
      <Column v-for="col in props.fields" :key="col" :field="col" :header="col" sortable></Column>
    </DataTable>
  </div>
</template>

<script setup>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { useStore } from 'vuex';
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import ProgressBar from 'primevue/progressbar';

const store = useStore();
const props = defineProps({
  fields: Array,
  list: Array,
});
const emit = defineEmits(['selected']);

// const dividerRef = ref(null);

const filteredList = ref([]);
const selectedItem = ref();
onMounted(() => {
  filteredList.value = [...props.list];
});

watch(selectedItem, (item) => {
  if (item) {
    emit('selected', item);
  }
});
</script>

<style scoped lang="scss">
.p-datatable {
  margin: 0;

  &-thead {
    position: sticky;
    top: 0;
    background: white;
  }

  &-header-cell {
    width: auto;
  }
}
</style>
