<template>
  <FloatLabel class="w-100" variant="on">
    <Select
      v-model="selected"
      :options="props.items"
      :showClear="showResetButton"
      :filter="showFilter"
      :filterPlaceholder="props?.title"
      :resetFilterOnClear="true"
      :resetFilterOnHide="true"
      :optionLabel="props.itemField"
      inputId="on_label"
      class="w-100"
    >
      <template #value="item">
        <i class="bi icon-text" :class="itemClass(item.value)">
          {{ item.value?.[props.itemField] }}
        </i>
      </template>
      <template #option="item">
        <i class="bi icon-text" :class="itemClass(item.option)">
          {{ item.option?.[props.itemField] }}
        </i>
      </template>
    </Select>
    <label for="on_label">{{ props.title }}</label>
  </FloatLabel>
</template>

<script setup>
import FloatLabel from 'primevue/floatlabel';
import Select from 'primevue/select';
import { defineEmits, defineProps, ref, watch } from 'vue';

const props = defineProps({
  items: Array,
  title: String,
  itemField: {
    type: String,
    default: 'name',
  },
  initValue: {
    type: Object,
    default: null,
  },
  itemIcon: {
    type: Function,
    default: null,
  },
  showResetButton: {
    type: Boolean,
    default: true,
  },
  showFilter: {
    type: Boolean,
    default: true,
  },
  useActiveField: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['select', 'reset']);

const selected = ref(props.initValue);

const itemClass = (item) => {
  if (!item) return '';

  let result = props.itemIcon?.(item) + ' select-icon';
  if (props.useActiveField && !item.is_active) {
    result += ' inactive';
  }

  return result;
};

watch(selected, (item) => {
  if (item) {
    emit('select', item);
  } else {
    emit('reset');
  }
});

watch(
  () => props.items,
  () => {
    selected.value = null;
  }
);
</script>

<style lang="scss" scoped>
.dropdown-menu {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.icon-text {
  display: inline-flex;
  align-items: center;
  font-style: normal;
  gap: 8px;
}

.inactive {
  color: #aaa;
}

.select-icon {
  margin-right: 8px;
}
</style>
