<template>
  <a class="d-none" target="_blank" ref="a"></a>
  <input class="d-none" type="file" ref="file" />
  <div class="d-none" ref="tokenModal" data-bs-toggle="modal" data-bs-target="#tokenModal"></div>

  <AutoComplete
    v-model="selectedSearch"
    :suggestions="searchItems"
    placeholder="Поиск точки по адресу"
    class="mx-2"
    optionLabel="value"
    @complete="search"
  ></AutoComplete>

  <Button type="button" icon="pi pi-ellipsis-h" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
  <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true">
    <template #submenulabel="{}">
      <Divider />
    </template>
    <template #item="{ item, props }">
      <a v-ripple class="flex items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span>{{ item.label }}</span>
        <span v-if="item.info" class="pi pi-info-circle mx-2" @click="(e) => e.stopPropagation() || item.info()" />
        <span v-if="item.delete" class="pi pi-times-circle mx-2" @click="(e) => e.stopPropagation() || item.delete()" />
      </a>
    </template>
  </Menu>

  <Dialog
    v-model:visible="helpDialogVisible"
    modal
    header="Как всё устроено:"
    :style="{ width: '900px', height: '75vh' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <Divider />
    <iframe :src="src" class="help-modal"> </iframe>
    <Divider />
    {{ version }}
  </Dialog>
</template>
<script setup>
import { VERSION_INJECT_TOKEN } from '@/core/utils';
import { env } from '@/env/env';
import { fileService } from '@/services/FileService';
import { httpService } from '@/services/HttpService';
import { mapService } from '@/services/MapService';
import { MUTATION_FORGOT_TOKEN, MUTATION_SHOW_TOKEN, MUTATION_USE_TOKEN } from '@/store/store';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Menu from 'primevue/menu';
import { inject, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const version = inject(VERSION_INJECT_TOKEN);
const src = `${env.baseUrl}/heatmap_backend/static/help.html?${version}`;

const store = useStore();

// TODO убрать
const a = ref();
const file = ref();
const tokenModal = ref();

const helpDialogVisible = ref(false);

const selectedSearch = ref();
const searchItems = ref([]);
const search = ({ query }) => {
  httpService.search(query).then(({ data }) => {
    searchItems.value = data.suggestions;
  });
};

const menu = ref();

const transformTokens = (tokens) =>
  tokens.map((token) => ({
    label: token.fname,
    icon: 'pi pi-table',
    command: () => store.commit(MUTATION_USE_TOKEN, token),
    info: () => store.commit(MUTATION_SHOW_TOKEN, token),
    delete: () => store.commit(MUTATION_FORGOT_TOKEN, token.token),
  }));

const menuItems = ref([
  {
    label: 'Загрузить данные',
    icon: 'pi pi-cloud-upload',
    command: () => fileService.upload(),
  },
  { label: 'Скачать шаблон', icon: 'pi pi-cloud-download', command: () => fileService.downloadTemplate() },
  { label: 'Экспорт в xlsx', icon: 'pi pi-cloud-download', command: () => fileService.exportToxlsx() },
  { label: 'Справка', icon: 'pi pi-question-circle', command: () => (helpDialogVisible.value = true) },
  {
    label: 'Custom workplaces',
    items: transformTokens(store.state.tokens),
  },
]);

onMounted(() => {
  fileService.init({ a, file, tokenModal });
});

watch(
  () => store.state.tokens,
  () => {
    menuItems.value[4].items = transformTokens(store.state.tokens);
  }
);

watch(selectedSearch, () => {
  const center = [selectedSearch.value?.data?.geo_lon, selectedSearch.value?.data?.geo_lat];

  if (center[0] && center[1]) {
    mapService.map.flyTo({ center, zoom: 17 });
  }
});

const toggle = (e) => {
  menu.value.toggle(e);
};
</script>
<style lang="scss">
.p-divider {
  margin: 0 !important;
}

.help-modal {
  height: calc(75vh - 136px);
  width: 100%;
  border: none;
}
</style>
