<template>
  <div>
    <div class="d-flex fs-4 gap-2 mb-2" style="color: #eabc00">
      <i :class="getStarClass(props.items[ratingKey], 1)"></i>
      <i :class="getStarClass(props.items[ratingKey], 2)"></i>
      <i :class="getStarClass(props.items[ratingKey], 3)"></i>
      <i :class="getStarClass(props.items[ratingKey], 4)"></i>
      <i :class="getStarClass(props.items[ratingKey], 5)"></i>
    </div>

    <div v-for="field in fieldsUpdated" :key="field">{{ field }} : {{ props.items[field] }}</div>

    <table class="table">
      <thead>
        <tr>
          <th>специальность</th>
          <th>кол-во</th>
          <th>оплата за час</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in table" :key="row">
          <td>{{ row['caption'] }}</td>
          <td>{{ row['count'] }}</td>
          <td>{{ Math.round(row['salary_per_hour']) }}</td>
        </tr>
      </tbody>
    </table>
    <div>ID: {{ props.items.id }}</div>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue';
import { ratingKey, getStarClass } from './PopupUtils';

const props = defineProps({
  items: Array,
  table: Array,
  fields: Array,
  tableFields: Array,
});

const fieldsUpdated = ref([...props.fields.filter((i) => !['Специальность', 'Рейтинг', 'Ставка'].includes(i))]);
</script>

<style lang="scss" scoped>
table {
  margin: 0;
}
</style>
