import { cleanParams } from '@/core/utils';
import { env } from '@/env/env';
import axios from 'axios';

class HttpService {
  constructor() {
    const params = new URLSearchParams(location.search);
    let token = params.get('token');

    this.axios = axios.create({
      baseURL: env.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }

  get(url, config) {
    return this.axios.get(url, config).catch(console.log);
  }

  /**
   *
   * @param {'clean' | 'hex' | 'filters'} type
   * @param {string} url
   * @param {Object} paramsRaw
   * @param {Object} config
   * @returns
   */
  post(type = 'clean', url, paramsRaw, config = {}) {
    let params = paramsRaw;

    if (type === 'filters') {
      params = cleanParams({
        ...params,
        ...window?.store?.getters?.filters,
      });
    }

    if (type === 'hex') {
      params = cleanParams({
        ...params,
        filters: cleanParams(window?.store?.getters?.filters),
      });
    }

    return this.axios.post(url, params, config).catch(console.log);
  }

  uploadPost(file) {
    const formData = new FormData();
    formData.append('inp', file);
    return this.axios
      .post(env.api.custom_workplaces, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(console.log);
  }

  search(query) {
    return this.axios
      .post(env.api.dadata, {
        query,
      })
      .catch(console.log);
  }
}

export const httpService = new HttpService();
