import { env } from '@/env/env';
import { bboxPolygon, buffer, polygon } from '@turf/turf';
import { cellsToMultiPolygon, getHexagonEdgeLengthAvg, polygonToCells, UNITS } from 'h3-js';
import { catchError, EMPTY, from, map, switchMap } from 'rxjs';
import { fixLatBounds, fixLatToPositive, fixLngBounds, mapZoomToHeatmapHexZoom, mapZoomToHexZoom, SOURCES } from '@/core/utils';
import { httpService } from './HttpService';
import { hexUpdate$ } from './UpdateHooks';

class HexService {
  constructor() {
    this.updater$ = hexUpdate$;
    this.hexMemcache = {};
    this.type = null;
    this.isHeatmapHex = false;
  }

  init(map) {
    this.map = map;

    this.getZoom = () => mapZoomToHexZoom(map.getZoom());
    this.getHeatmapZoom = () => mapZoomToHeatmapHexZoom(map.getZoom());

    this.initUpdaterSubscription();

    this.updater$.next(true);
    this.map.on('moveend', () => {
      if (this.type == null) return;

      this.updater$.next(false);
    });
  }

  clearHex() {
    this.type = null;
    this.isHeatmapHex = false;
    this.hexMemcache = {};

    this.map.getSource(SOURCES.hex).setData({ type: 'FeatureCollection', features: [] });
  }

  // может сработать до или после init
  updateType(type, isHeatmapHex = false) {
    if (this.type === type && this.isHeatmapHex === isHeatmapHex) return;

    this.type = type;
    this.isHeatmapHex = isHeatmapHex;
    this.updater$.next(true);
  }

  initUpdaterSubscription() {
    this.updater$
      .pipe(
        // нужно для того, чтобы не создавать очереди запросов

        switchMap((resetMemcache) => {
          if (!this.type) {
            return EMPTY;
          }

          // Тут была преждевременная потимизация - можно запрашивать только те хексы, которые не видны
          // Оптимизация не допилена, без этой строчки возникают чёрные хексы
          this.hexMemcache = {};
          if (resetMemcache) {
            this.hexMemcache = {};
          }

          const mapBounds = this.map.getBounds().toArray();
          let hexZoom = this.isHeatmapHex ? this.getHeatmapZoom() : this.getZoom();

          const bufferRaduis = getHexagonEdgeLengthAvg(hexZoom, UNITS.km) * 1.5; // * 360) / 40_000;

          // x = lat, y = lng
          const min_x = Math.min(fixLatBounds(mapBounds[0][0]), fixLatBounds(mapBounds[1][0]));
          const min_y = fixLngBounds(Math.min(mapBounds[0][1], mapBounds[1][1]));
          const max_x = Math.max(fixLatBounds(mapBounds[0][0]), fixLatBounds(mapBounds[1][0]));
          const max_y = fixLngBounds(Math.max(mapBounds[0][1], mapBounds[1][1]));
          const mapCenterLat = (min_x + max_x) / 2;

          // делим границы на 2 для того, чтобы правильно определялась сторона планеты (если ширина границы больше, чем 180, h3-js возвращает данные по меньшей части планеты)

          const bounds_left = buffer(bboxPolygon([min_x, min_y, mapCenterLat, max_y]), bufferRaduis);
          const bounds_right = buffer(bboxPolygon([mapCenterLat, min_y, max_x, max_y]), bufferRaduis);

          const visibleCells = [
            ...new Set([
              ...polygonToCells(bounds_left.geometry.coordinates, hexZoom, true),
              ...polygonToCells(bounds_right.geometry.coordinates, hexZoom, true),
            ]),
          ];

          const newHexMemcache = {};
          const cellsVithoutGeom = [];

          for (let id of visibleCells) {
            if (!this.hexMemcache[id]) {
              cellsVithoutGeom.push(id);
            }

            newHexMemcache[id] = this.hexMemcache[id]; // видимые, но уже загруженные и рассчитанные
          }

          if (!cellsVithoutGeom.length) {
            return EMPTY;
          }

          const new_h3 = cellsVithoutGeom.reduce((result, id) => {
            let rawHex = cellsToMultiPolygon([id], true)[0];

            // если не у всех точек одинаковый знак долготы, исрпавляем на положительный
            if (Math.abs(rawHex[0][0][0]) > 170) {
              const firstSign = Math.sign(rawHex[0][0][0]);

              if (rawHex[0].some((coord) => Math.sign(coord[0]) !== firstSign)) {
                // FIX rawHex[0] = rawHex[0].map((lngLat) => [fixLatToPositive(lngLat[0]), lngLat[1]]);
                rawHex[0] = rawHex[0].map((lngLat) => [fixLatToPositive(lngLat[0]), lngLat[1]]);
              }
            }

            result[id] = polygon(rawHex);

            return result;
          }, {});

          delete this.hexMemcache; // должно предотвратить утечки памяти
          this.hexMemcache = { ...newHexMemcache, ...new_h3 };

          return from(
            httpService.post('hex', this.isHeatmapHex ? env.api.get_heatmap_hexes : env.api.get_hexes, {
              ids: cellsVithoutGeom,
              type: this.type,
            })
          ).pipe(
            map(({ data }) => {
              for (let item of data) {
                if (!this.hexMemcache[item.id]) {
                  continue;
                }
                this.hexMemcache[item.id].properties.weight = item.weight;
                this.hexMemcache[item.id].properties.color = item.color;
              }
            }),
            catchError((e) => {
              console.warn('Ошибка в цикле отрисовки хексов: Не получили ответ от сервера', e);

              return EMPTY;
            })
          );
        }),
        catchError((e) => {
          console.warn('Ошибка в цикле отрисовки хексов', e);

          return EMPTY;
        })
      )
      // сюда придём только если все предыдущие этапы прошли без ошибок
      .subscribe(() => {
        const data = {
          type: 'FeatureCollection',
          features: [],
        };

        for (let id in this.hexMemcache) {
          data.features.push(this.hexMemcache[id]);
        }

        this.map.getSource(SOURCES.hex).setData(data);
      });
  }
}

export const hexService = new HexService();
