<template>
  <TokenModal></TokenModal>
  <ContextMenu ref="contextMenuRef" :model="contextMenuItems"></ContextMenu>

  <Splitter class="content" layout="vertical">
    <SplitterPanel :size="60" class="flex items-center justify-center">
      <div class="map" ref="mapRef"></div>
      <div class="w-full h-full d-flex flex-column-reverse">
        <div class="z-map content__search">
          <SearchComponent></SearchComponent>
        </div>

        <div class="z-map content__filter">
          <FilterComponent></FilterComponent>
        </div>

        <div class="z-map content__legend bg-light">
          <HeatmapLegendComponent></HeatmapLegendComponent>
        </div>
        <div class="content__etl fs-6 fw-light p-1">Данные от {{ store.state.etl?.format }}</div>
      </div>
    </SplitterPanel>
    <SplitterPanel :size="40" class="flex items-center justify-center">
      <TableComponent
        :list="store.state.tableData"
        :fields="store.state.tableFields"
        class="z-map content__table bg-light"
        @selected="selectItem"
      ></TableComponent>
    </SplitterPanel>
  </Splitter>
</template>

<script setup>
import FilterComponent from '@/components/FilterComponent.vue';
import HeatmapLegendComponent from '@/components/LegendComponent.vue';
import TokenModal from '@/components/modals/TokenModal.vue';
import WorkplacePopup from '@/components/popups/WorkplacePopup';
import WorkplacesListPopup from '@/components/popups/WorkplacesListPopup.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import { LAYERS } from '@/core/utils';
import { env } from '@/env/env';
import { hexService } from '@/services/HexService.js';
import { httpService } from '@/services/HttpService';
import { mapService } from '@/services/MapService.js';
import { ACTION_INIT_AFTER_MAP, ACTION_INIT_BEFORE_MAP, MUTATION_FETCH_LEGEND } from '@/store/store';
import { Popup } from 'maplibre-gl';
import ContextMenu from 'primevue/contextmenu';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import { createApp, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const mapRef = ref(null);
const contextMenuRef = ref(null);
const contextMenuItems = ref([]);

let currentPopup;
let popupDiv;

const hexClickCallback = (e) => {
  currentPopup?.remove();

  currentPopup = new Popup()
    .setLngLat(e.lngLat)
    .setHTML(`${store.state.currentHexType.name}: ${e.features[0].properties.weight}`)
    .addTo(mapService.map);
};

const workplaceClickCallback = (e) => {
  currentPopup?.remove();
  currentPopup = new Popup().setLngLat(e.lngLat).addTo(mapService.map);

  const features = e.features;

  features.length === 1 ? openWorkplacePopup(features[0]) : openWorkspacesListPopup(features, e);
};

const openWorkspacesListPopup = (features, e) => {
  popupDiv = document.createElement('div');
  createApp(WorkplacesListPopup, {
    features,
    openPopup: (f) => {
      currentPopup?.remove();
      currentPopup = new Popup().setLngLat(e.lngLat).addTo(mapService.map);
      openWorkplacePopup(f);
    },
  }).mount(popupDiv);
  currentPopup.setDOMContent(popupDiv).setMaxWidth('500px');
};

const openWorkplacePopup = (feature) => {
  const featureProperties = feature.properties;

  popupDiv = document.createElement('div');
  const openPopup = (popupData) => {
    createApp(WorkplacePopup, popupData).mount(popupDiv);
    currentPopup.setDOMContent(popupDiv).setMaxWidth('500px');
  };

  if (featureProperties.custom) {
    return openPopup({
      items: featureProperties,
      fields: store.state.tableFields,
      table: [],
      tableFields: [],
    });
  }

  httpService.post('filters', `${env.api.workplaces_specs}${featureProperties.id}/`).then((resp) => {
    const data = resp?.data || [];
    openPopup({
      items: featureProperties,
      fields: store.state.tableFields,
      table: data,
      tableFields: Object.keys(data[0]),
    });
  });
};

// hooks

onMounted(() => {
  mapService.initMap(mapRef.value);
  store.dispatch(ACTION_INIT_BEFORE_MAP);

  mapService.map.on('load', () => {
    store.dispatch(ACTION_INIT_AFTER_MAP);
    hexService.init(mapService.map);

    let prevZoom = mapService.map.getZoom();
    mapService.map.on('zoomend', () => {
      const zoom = mapService.map.getZoom();

      if (Math.floor(prevZoom) !== Math.floor(zoom)) {
        currentPopup?.remove();
        store.commit(MUTATION_FETCH_LEGEND, Math.floor(zoom));
      }

      prevZoom = zoom;
    });

    mapService.map.on('click', LAYERS.hex, hexClickCallback);
    mapService.map.on('click', LAYERS.workpaces, workplaceClickCallback);
    mapService.map.on('click', LAYERS.custom_workpaces, workplaceClickCallback);
  });

  window.onmessage = function (event) {
    if (event.data == 'message') {
      console('Message received!', event);
    }
  };
});

onBeforeUnmount(() => {
  mapService.map.off('click', LAYERS.hex, hexClickCallback);
  mapService.map.off('click', LAYERS.workpaces, workplaceClickCallback);
  mapService.map.off('click', LAYERS.custom_workpaces, workplaceClickCallback);
});

// methods

const selectItem = (item) => {
  mapService.map.flyTo({
    center: item.geo,
    zoom: 15,
  });
};
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.content {
  height: 100vh;
  width: 100vw;

  &__legend {
    position: absolute;

    margin-bottom: 8px;
    right: 8px;
    border-radius: 16px;
  }
  &__search {
    position: absolute;
    right: 0;
    top: 0;
    margin: 8px;
  }
  &__filter {
    position: absolute;
    left: 0;
    top: 0;
    margin: 8px;
  }
  &__etl {
    position: absolute;
    left: 0;
    z-index: 19;
  }
}

.map {
  border: 0;
  width: 100vw;
  height: 100%;
}

.z-map {
  z-index: 20;
}
</style>
