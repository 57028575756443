import { getProfessionsObject } from '@/core/utils';
import { env } from '@/env/env';
import { httpService } from '@/services/HttpService';
import { hookAllFilters } from '@/services/UpdateHooks';

export const mFetchProfessions = (state) => {
  httpService.get(env.api.professions).then(({ data }) => {
    state.rawProfessions = data;
    state.professions = Object.keys(data).map((key) => getProfessionsObject(key, state));
  });
};

export const mSelectProfession = (state, item) => {
  state.selectedProfession = item;
  hookAllFilters();
};
