<template>
  <div class="content fs-6">
    <div>Несколько объектов в одной точке</div>
    <div>Выберите нужный из списка</div>

    <button v-for="item in features" :key="item" class="btn feature-button" @click="() => props.openPopup(item)">
      <div class="d-flex gap-2 mb-2" style="color: #eabc00">
        <i :class="getStarClass(item.properties[ratingKey], 1)"></i>
        <i :class="getStarClass(item.properties[ratingKey], 2)"></i>
        <i :class="getStarClass(item.properties[ratingKey], 3)"></i>
        <i :class="getStarClass(item.properties[ratingKey], 4)"></i>
        <i :class="getStarClass(item.properties[ratingKey], 5)"></i>
      </div>

      <div>{{ item.properties[previewKey] }}</div>
    </button>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
import { getStarClass, ratingKey, previewKey } from './PopupUtils';

const props = defineProps({
  features: Array,
  openPopup: Function,
});
</script>

<style lang="scss" scoped>
.content {
  height: 200px;
  overflow-y: scroll;

  display: flex;
  flex-flow: column nowrap;
  gap: 2px;
}

.feature-button {
  font-size: small !important;
}
</style>
