import { point } from '@turf/turf';
import { Map } from 'maplibre-gl';
import { LAYERS, MAP_MAX_ZOOM, MAP_MIN_ZOOM, SOURCES } from '@/core/utils';

const getEmptySource = () => ({
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: [],
  },
});

class MapService {
  constructor() {
    this.hexClickCallback = () => {};
  }

  initMap(container) {
    this.map = new Map({
      container,
      style: this.getFullStyle(),
      center: [40, 61.2],
      zoom: MAP_MIN_ZOOM,
      maxZoom: MAP_MAX_ZOOM,
      minZoom: MAP_MIN_ZOOM,
      hash: true, // DEBUG false
      maplibreLogo: false,
    });

    this.map.dragRotate.disable();
    this.map.keyboard.disable();
    this.map.touchZoomRotate.disableRotation();

    window.map = this.map;
  }

  updateWorkplaceSource(workplaces, custom = false) {
    const data = {
      type: 'FeatureCollection',
      features: workplaces.reduce((sum, item) => {
        const p = point(item.geo, {
          ...item,
          custom,
        });

        sum.push(p);

        return sum;
      }, []),
    };

    const source = custom ? SOURCES.custom_workplaces : SOURCES.workplaces;
    this.map.getSource(source).setData(data);
  }

  getFullStyle() {
    return {
      version: 8,
      name: 'Raster OSM Tiles',
      glyphs:
        'https://smellman.github.io/creating_tiles_with_global_map_support_files/2015/mapbox_vector_tile_demo/demosites/fonts/{fontstack}/{range}.pbf',
      sprite:
        'https://smellman.github.io/creating_tiles_with_global_map_support_files/2015/mapbox_vector_tile_demo/demosites/maki-sprites/sprite',
      sources: {
        ...Object.keys(SOURCES).reduce((result, key) => {
          result[SOURCES[key]] = getEmptySource();
          return result;
        }, {}),
        osm: {
          type: 'raster',
          tiles: [
            'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
            'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
            'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png',
          ],
          tileSize: 256,
        },
      },
      layers: [
        {
          id: 'osm',
          type: 'raster',
          source: 'osm',
          paint: {
            'raster-saturation': -1,
          },
        },
        {
          id: LAYERS.hex,
          type: 'fill',
          source: SOURCES.hex,
          paint: {
            'fill-color': ['get', 'color'], // DEBUG '#f003',
          },
          layout: {
            visibility: 'visible',
          },
        },
        {
          id: LAYERS.workpaces,
          type: 'circle',
          source: SOURCES.workplaces,
          paint: {
            'circle-radius': 7,
            'circle-color': '#06f',
            'circle-opacity': 1,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
          layout: {
            visibility: 'visible',
          },
        },
        {
          id: LAYERS.custom_workpaces,
          type: 'circle',
          source: SOURCES.custom_workplaces,
          paint: {
            'circle-radius': 7,
            'circle-color': '#f60',
            'circle-opacity': 1,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
          layout: {
            visibility: 'visible',
          },
        },
        {
          id: LAYERS.heatmap,
          source: SOURCES.workplaces,
          type: 'heatmap',
          paint: {
            ...this.getHeatmapColors(),
            'heatmap-radius': this.getConstantRadiusFunction(120),
            'heatmap-opacity': 0.3,
            'heatmap-weight': 0.5,
          },
          layout: {
            visibility: 'none',
          },
        },
      ],
    };
  }

  getConstantRadiusFunction(clusterRadius) {
    const result = {
      type: 'exponential',
      base: 2,
      stops: [],
    };

    for (const item of [1, 22]) {
      result.stops.push([item, this.getRadiusByZoom(item, clusterRadius)]);
    }
    return result;
  }

  /** Выдаёт радиус кластера в зависимости от зума и изначального радиуса кластера.
   * Расчёт происходит так, чтобы при зуме кластер уменьшался/увеличивался пропорционально
   */
  getRadiusByZoom(zoom, clusterRadius) {
    const result = (512 * 2 ** zoom * clusterRadius) / 6371000; // все вычисления в метрах
    return result > 1 ? result : 1;
  }

  getHeatmapColors() {
    return {
      'heatmap-color': ['interpolate', ['linear'], ['heatmap-density'], 0, '#00f0', 0.001, '#0f00', 0.01, '#0f0', 0.1, '#ff0', 1, '#f00'],
    };
  }
}

export const mapService = new MapService();
