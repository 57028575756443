export const ratingKey = 'Рейтинг';
export const previewKey = 'Адрес';

export const getStarClass = (rating, index) => {
  const diff = (rating || 0) - index;

  switch (true) {
    case diff >= 0.75:
      return 'bi bi-star-fill';

    case diff <= 0.25:
      return 'bi bi-star';

    default:
      return 'bi bi-star-half';
  }
};
