<template>
  <Panel header="Фильтры" toggleable>
    <div class="d-flex flex-column gap-2">
      <DropdownListComponent
        v-if="store.state.currentHexType"
        title="Тип данных"
        :items="store.state.hexTypes"
        :init-value="store.state.currentHexType"
        :showResetButton="false"
        :showFilter="false"
        :item-icon="(type) => (type.isHeatmapHex ? 'bi-map' : 'bi-hexagon')"
        @select="(type) => store.commit(MUTATION_SET_CURRENT_HEX_TYPE, type)"
      ></DropdownListComponent>

      <DropdownListComponent
        title="Регион"
        :items="store.state.regionsList"
        @select="(region) => store.commit(MUTATION_SET_CURRENT_REGION, region.id)"
        @reset="() => store.commit(MUTATION_RESET_CURRENT_REGION)"
      ></DropdownListComponent>
      <DropdownListComponent
        title="Город"
        :items="store.state.towns?.[store.state.currentRegion?.id]?.list ?? []"
        @select="(town) => store.commit(MUTATION_SET_CURRENT_TOWN, town.id)"
        @reset="() => store.commit(MUTATION_RESET_CURRENT_TOWN)"
      ></DropdownListComponent>

      <DropdownListComponent
        title="Группа проектов"
        :items="store.state.projectGroups"
        :use-active-field="true"
        @select="(group) => store.commit(MUTATION_SET_CURRENT_PROJECT_GROUP, group.id)"
        @reset="() => store.commit(MUTATION_RESET_CURRENT_PROJECT_GROUP)"
      ></DropdownListComponent>
      <DropdownListComponent
        title="Проект"
        :items="store.state.projects[store.state.currentProjectGroup?.id]"
        :use-active-field="true"
        :showFilter="false"
        @select="(project) => store.commit(MUTATION_SET_CURRENT_PROJECT, project.id)"
        @reset="() => store.commit(MUTATION_RESET_CURRENT_PROJECT)"
      ></DropdownListComponent>

      <DropdownListComponent
        title="Профессия"
        :items="store.state.professions"
        @select="(prof) => store.commit(MUTATION_SELECT_PROFESSION, prof)"
        @reset="() => store.commit(MUTATION_SELECT_PROFESSION, null)"
      ></DropdownListComponent>

      <div v-for="item in store.state.allContracts" :key="item">
        <Checkbox v-model="selectedContractTypes" :inputId="item.id" :name="item.name" :value="item.id" />
        <label class="mx-2" :for="item.id"> {{ item.name }} </label>
      </div>

      <FloatLabel variant="on">
        <DatePicker v-model="dateStart" :maxDate="dateEnd" dateFormat="dd/mm/yy" />
        <label for="on_label">От</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <DatePicker v-model="dateEnd" :minDate="dateStart" dateFormat="dd/mm/yy" />
        <label for="on_label">До</label>
      </FloatLabel>
    </div>
  </Panel>
</template>

<script setup>
import {
  MUTATION_RESET_CURRENT_PROJECT,
  MUTATION_RESET_CURRENT_PROJECT_GROUP,
  MUTATION_RESET_CURRENT_REGION,
  MUTATION_RESET_CURRENT_TOWN,
  MUTATION_SELECT_PROFESSION,
  MUTATION_SET_CURRENT_CONTRACT_TYPE,
  MUTATION_SET_CURRENT_HEX_TYPE,
  MUTATION_SET_CURRENT_PROJECT,
  MUTATION_SET_CURRENT_PROJECT_GROUP,
  MUTATION_SET_CURRENT_REGION,
  MUTATION_SET_CURRENT_TOWN,
  MUTATION_SET_DATE_END,
  MUTATION_SET_DATE_START,
} from '@/store/store';
import Checkbox from 'primevue/checkbox';
import DatePicker from 'primevue/datepicker';
import FloatLabel from 'primevue/floatlabel';
import Panel from 'primevue/panel';
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import DropdownListComponent from '../core/DropdownListComponent.vue';

const store = useStore();

const selectedContractTypes = ref([...store.state.contract]);

const dateStart = ref(new Date(store.state.dateStart));
const dateEnd = ref(new Date(store.state.dateEnd));

watch(selectedContractTypes, (contract) => {
  store.commit(MUTATION_SET_CURRENT_CONTRACT_TYPE, contract);
});

watch(dateStart, (date) => {
  store.commit(MUTATION_SET_DATE_START, date);
});
watch(dateEnd, (date) => {
  store.commit(MUTATION_SET_DATE_END, date);
});
</script>

<style lang="scss">
.p-panel {
  width: 240px;
}
</style>
